import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from 'api/axios';
import { TokenType, LoginAdminType, LoginResponse } from 'types/user';
import { decodeToken } from 'react-jwt';
import { sendCatchFeedback } from 'functions/feedback';

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  clientId: TokenType['clientId'] | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  loading: false,
  error: null,
  clientId: null,
};

// Async thunk for logging in the user
export const loginUser = createAsyncThunk<LoginResponse, LoginAdminType, { rejectValue: string }>(
  'auth/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      // Send login request to backend
      const response = await appAxios.post('/user-login', credentials);
      return response.data;
    } catch (error: any) {
      // Log error details for debugging
      console.error('Login error:', error.response || error.message);
      sendCatchFeedback(error); // Display user-friendly feedback
      return rejectWithValue(error.response?.data?.message || 'Login failed');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.clientId = null;
      localStorage.removeItem('token'); // Clear token on logout
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;

        const token = action.payload?.data?.token;
        if (token) {
          try {
            // Save token to localStorage
            localStorage.setItem('token', token);

            // Decode the token
            const decodedToken = decodeToken<TokenType>(token);

            if (decodedToken && decodedToken.clientId) {
              state.clientId = decodedToken.clientId; // Save clientId from token
            } else {
              state.error = 'Invalid token received';
              console.error('Client ID missing in token');
            }
          } catch (error) {
            console.error('Error decoding token:', error); // Log token decoding error
            state.error = 'Error decoding token';
          }
        } else {
          state.error = 'Token is missing from the response';
          console.error('Token missing from response'); // Log missing token issue
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Login failed';
        console.error('Login rejected:', action.payload); // Log rejected login with the message
      });
  },
});

// Export the logout action
export const { logout } = authSlice.actions;

// Export the reducer
export default authSlice.reducer;
